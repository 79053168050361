import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Logo from '../../Asserts/logo.png';
import './Navv.css'; // Custom CSS

const Navv = () => {
    const location = useLocation();
    const [activeLink, setActiveLink] = useState('');
    const offcanvasRef = useRef(null);

    // Route configuration
    const navItems = [
        { label: 'Home', path: '/' },
        { label: 'About Us', path: '/about' },
        { label: 'Product', path: '/product' },
        { label: 'Our Works', path: '/our-works' },
        { label: 'Testimonial & Blogs', path: '/testimonial_blogs' },
        { label: 'Contact Us', path: '/contact' }
    ];

    useEffect(() => {
        // Set active link based on current path
        const currentPath = location.pathname;
        const activeNavItem = navItems.find(item => item.path === currentPath);
        setActiveLink(activeNavItem ? activeNavItem.label : '');
    }, [location, navItems]);

    const handleLinkClick = (label) => {
        setActiveLink(label);

        // Close the offcanvas menu if Bootstrap Offcanvas is used
        const offcanvas = offcanvasRef.current;
        if (offcanvas && window.bootstrap) {
            const bsOffcanvas = window.bootstrap.Offcanvas.getInstance(offcanvas);
            if (bsOffcanvas) bsOffcanvas.hide();
        }
    };

    return (
        <nav className="navbar navbar-expand-lg fixed-top">
            <div className="container-nav">
                <Link className="navbar-brand me-5" to="/">
                    <img src={Logo} alt="Logo" className="logo" />
                </Link>

                <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasNavbar"
                    aria-controls="offcanvasNavbar"
                    aria-label="Toggle navigation"
                >
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div
                    className="offcanvas offcanvas-end"
                    tabIndex="-1"
                    id="offcanvasNavbar"
                    aria-labelledby="offcanvasNavbarLabel"
                    ref={offcanvasRef}
                >
                    <div className="offcanvas-header">
                        <img src={Logo} alt="Logo" className="logo" />
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="offcanvas"
                            aria-label="Close"
                        ></button>
                    </div>
                    <div className="offcanvas-body">
                        <ul className="navbar-nav justify-content-between flex-grow-1 pe-3">
                            {navItems.map(({ label, path }) => (
                                <li className="nav-item" key={path}>
                                    <Link
                                        className={`nav-link ${activeLink === label ? 'active' : ''}`}
                                        to={path}
                                        onClick={() => handleLinkClick(label)}
                                    >
                                        {label}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default Navv;
