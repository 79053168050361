import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import logoimg from "../../Asserts/logo.png";
import image1 from '../../Asserts/ROOKS AND BROOKS final png file-01.png';
import { IoLogoWhatsapp } from "react-icons/io";
import { FaFacebook, FaTwitter } from "react-icons/fa";
import { RiInstagramFill } from "react-icons/ri";
import './Footer.css';

const Footer = () => {
    const [showMap, setShowMap] = useState(false);
    const [selectedLocation, setSelectedLocation] = useState('');
    const navigate = useNavigate();

    const handleMapClick = (location) => {
        setSelectedLocation(location);
        setShowMap(true);
    };

    const closeMap = () => {
        setShowMap(false);
        setSelectedLocation('');
    };

    const handleTimelineClick = () => {
        navigate('/about');
        setTimeout(() => {
            document.getElementById("timeline-section")?.scrollIntoView({ behavior: "smooth" });
        }, 300);
    };

    return (
        <>
            <div className="footer-container">
                <div className="footer-content">

                    <div className="footer-logo">
                        <img src={logoimg} className="logo-img" alt="Jothidecor Logo" />
                    </div>

                    <div className="footer-company">
                        <p className="footer-heading">Company</p>
                        <div className="footer-links">
                            <Link to="/" className="footer-link">Home</Link>
                            <Link to="/about" className="footer-link">About Us</Link>
                            <Link to="/product" className="footer-link">Product</Link>
                            <Link to="/our-works" className="footer-link">Our Works</Link>
                            <Link to="/testimonial_blogs" className="footer-link">Testimonial & Blogs</Link>
                            <Link to="/contact" className="footer-link">Contact Us</Link>
                        </div>
                    </div>


                    <div className="footer-locations">
                        <p className="footer-heading">Available in</p>
                        <div className="location-links">
                            <span className="location-link" onClick={() => handleMapClick('Nagercoil')} aria-label="Nagercoil location">Nagercoil</span>
                            <span className="location-link" onClick={() => handleMapClick('Marthandam')} aria-label="Marthandam location">Marthandam</span>
                        </div>

                        {showMap && (
                            <div className="map-modal">
                                <div className="map-content">
                                    <button onClick={closeMap} className="close-btn" aria-label="Close map modal">Close</button>
                                    <div className="map-frame">
                                        {selectedLocation === 'Nagercoil' && (
                                            <iframe
                                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3949.1332503673343!2d77.427556275876!3d8.18932950156526!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b04f12f329ebe37%3A0xa9f76127a75d8863!2sJothi%20Rajalekshmi%20Leather%20Mart%20%7C%20Curtains%20and%20Furnishings!5e0!3m2!1sen!2sin!4v1731040453957!5m2!1sen!2sin"
                                                width="600"
                                                height="450"
                                                style={{ border: 0 }}
                                                allowFullScreen=""
                                                loading="lazy"
                                                referrerPolicy="no-referrer-when-downgrade"
                                            ></iframe>
                                        )}
                                        {selectedLocation === 'Marthandam' && (
                                            <iframe
                                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3948.012938302423!2d77.2311725758765!3d8.301510299950312!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b0455ae338200bb%3A0x54cea8334d920fd5!2sJothi%20Rajalekshmi%20Curtains%20and%20Furnishings%20%7C%20Mosquito%20Net%20in%20Marthandam!5e0!3m2!1sen!2sin!4v1731040401659!5m2!1sen!2sin"
                                                width="600"
                                                height="450"
                                                style={{ border: 0 }}
                                                allowFullScreen=""
                                                loading="lazy"
                                                referrerPolicy="no-referrer-when-downgrade"
                                            ></iframe>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>


                    <div className="footer-life-at-jothi">
                        <p className="footer-heading">Life at Jothi</p>
                        <span className="footer-link" onClick={handleTimelineClick}>Time Line</span>
                    </div>


                    <div className="footer-social-media">
                        <p className="footer-heading">Social Media Links</p>
                        <div className="social-icons1">
                            <a href="https://wa.me/917598434092?text=Hello!%20I%20am%20interested%20in%20your%20services." target="_blank" rel="noopener noreferrer" aria-label="WhatsApp"><IoLogoWhatsapp className='whatsapp-icon' /></a>
                            <a href="https://www.facebook.com/jothirajalekshmi/" target="_blank" rel="noopener noreferrer" aria-label="Facebook" className='facebook-icon'><FaFacebook /></a>
                            <a href="https://www.instagram.com/jothi_rajalekshmi" target="_blank" rel="noopener noreferrer" aria-label="Instagram" className='instagram-icon'><RiInstagramFill /></a>
                        </div>
                    </div>
                </div>
            </div>

            <div className="copy-right">
                <p>Copyright © 2024 - All Rights Reserved.</p>
                <img src={image1} alt="R&B Logo" />
            </div>
        </>
    );
};

export default Footer;
