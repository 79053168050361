import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navv from './Components/NavBar/Navv';
import Footer from './Components/Footer/Footer';

// Lazy loading components for code splitting
const Home = lazy(() => import('./Components/Home/Home'));
const AboutUs = lazy(() => import('./Components/AboutUs/About'));
const Product = lazy(() => import('./Components/Product/Product'));
const ContactUs = lazy(() => import('./Components/ContactUs/ContactUs'));
const Testimonial_Blogs = lazy(() => import('./Components/Testimonial_Blogs/Testimonial_Blogs'));
const FloatingToogleButton = lazy(() => import('./Components/FloatingButton/FloatingToogleButton'));
const OurWorks = lazy(() => import('./Components/OurWorks/OurWorks')); // Importing OurWorks component

const App = () => {
    return (
        <Router>
            {/* Navigation bar, appears on all pages */}
            <Navv />

            {/* Floating Toggle Button, appears on all pages */}
            <FloatingToogleButton />

            {/* Suspense provides a loading fallback while lazy-loaded components are being loaded */}
            <Suspense fallback={<div>Loading...</div>}>
                <Routes>
                    {/* Define all routes and corresponding lazy-loaded components */}
                    <Route path="/" element={<Home />} />
                    <Route path="/about" element={<AboutUs />} />
                    <Route path="/product" element={<Product />} />
                    <Route path="/testimonial_blogs" element={<Testimonial_Blogs />} />
                    <Route path="/contact" element={<ContactUs />} />
                    <Route path="/our-works" element={<OurWorks />} /> {/* Route for OurWorks */}
                </Routes>
            </Suspense>

            {/* Footer, appears on all pages */}
            <Footer />
        </Router>
    );
};

export default App;
